import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { DATA_FILTER_CONSTANTS as DFC } from '../../../constants';
export const _frontmatter = {
  "unique_id": "POR",
  "title": "Pacific Offshore Region",
  "includeToc": false,
  "description": "Pacific offshore region",
  "tags": ["offshore", "Pacific"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Container = makeShortcode("Container");
const Typography = makeShortcode("Typography");
const Box = makeShortcode("Box");
const Link = makeShortcode("Link");
const Grid = makeShortcode("Grid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Container maxWidth="lg" mdxType="Container">
  <Typography variant="h2" component="h1" mdxType="Typography">
    {props.pageContext.frontmatter.title}
  </Typography>
  <Box component="p" mdxType="Box">
    This page provides information about energy and minerals produced in federal waters in the {props.pageContext.frontmatter.title}.
  </Box>
  <Box component="p" mdxType="Box">
    The federal government manages the waters and submerged lands of the <Link href="https://www.boem.gov/oil-gas-energy/leasing/outer-continental-shelf" mdxType="Link">Outer Continental Shelf <OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></Link>. The first three nautical miles offshore (generally) are in the states' jurisdiction. This means that most <Link href="/how-revenue-works/offshore-oil-gas" mdxType="Link">offshore drilling</Link> and <Link href="/how-revenue-works/offshore-renewables/" mdxType="Link">renewable energy</Link> generation takes place in federal waters.
  </Box>
  <Box component="p" mdxType="Box">
    The Office of Natural Resources Revenue (ONRR) collects rent, royalty, and bonus payments from companies that lease the federal waters. Revenue from offshore production is disbursed to the states and counties that surround the offshore area.
  </Box>
  <Box mt={2} mdxType="Box">
    <Grid container spacing={1} mdxType="Grid">
      <Grid item xs={6} mdxType="Grid">
        <Link href={`/explore?dataType=${DFC.REVENUE}&offshoreRegions=true&location=NF,${props.pageContext.frontmatter.unique_id}/`} linkType="ExploreData" mdxType="Link">
            Explore revenue data in {props.pageContext.frontmatter.title} 
        </Link>
        <Link href={`/explore?dataType=${DFC.PRODUCTION}&offshoreRegions=true&location=NF,${props.pageContext.frontmatter.unique_id}/`} linkType="ExploreData" mdxType="Link">
          Explore production data in {props.pageContext.frontmatter.title}
        </Link>
      </Grid>
      <Grid item xs={6} mdxType="Grid">
        <Link href={`/query-data?dataType=${DFC.REVENUE}&stateOffshoreName=${'Offshore Pacific'}`} linkType="FilterTable" mdxType="Link">
          Query revenue data in {props.pageContext.frontmatter.title}
        </Link>
        <Link href={`/query-data?dataType=${DFC.PRODUCTION}&stateOffshoreName=${'Offshore Pacific'}`} linkType="FilterTable" mdxType="Link">
          Query production data in {props.pageContext.frontmatter.title}
        </Link>
        <Link href={`/query-data?dataType=${DFC.DISBURSEMENT}&landType=${'Federal Offshore'}&source=${'GOMESA offshore,Offshore,State 8(g)'}&stateOffshoreName=${props.pageContext.frontmatter.title}`} linkType="FilterTable" mdxType="Link">
          Query disbursements data from all offshore regions
        </Link>
      </Grid>
    </Grid>
  </Box>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      